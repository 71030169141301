ul {
    text-align: left;
}

.row {
    margin-bottom: 10px;
}
.card {
    min-height: 100%;
}

.radio-inline {
    margin-left: 5px;
}
input[type='radio'] {
    accent-color: #751d1d;
}
input[type='checkbox'] {
    accent-color: #751d1d;
}

.edit-icon {
    float:right;
    margin-top: 3px;
    opacity: 0;
    margin-left: -1.2em;
}
.edit-icon:hover {
    opacity: 1;
    cursor: pointer;
}

.new-icon {
    float:right;
    margin-top: 3px;
    opacity: 0.3;
    margin-left: -1.2em;
}
.new-icon:hover {
    opacity: 1;
    cursor: pointer;
}

.accordion-edit-icon {
    float:right;
    margin-top: 3px;
    opacity: .5;
    margin-left: -1.2em;
}
.accordion-edit-icon:hover {
    opacity: 1;
    cursor: pointer;
}
.add-icon{
    float:right;
    opacity: .5;
    margin-top: 3px;
    margin-left: -1.2em;
}
.add-icon:hover{
    opacity: 1;
    cursor: pointer;
}
.wounds {
    width: 90%;
    display: inline;
}