input {
    margin-bottom: 15px;
}
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
.close-btn {
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    top:0;
    right:0;

}