.dice {
    height: 20px;
    fill : "red";
}

.icon {
    display:inline-block;
    height: 20px;
    width: 20px;
    background-size: cover;
}
.icon-d4 {
    background-image: url('../img/d4_selected.svg');
    fill:red;
}